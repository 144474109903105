import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

import {accountSettingsUrl} from '../../../../lib/urlTools'
import {itemBySlug} from '../../../../lib/plan_data/item'
import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import MixpanelLogger from '../../../../lib/MixpanelLogger'
import PartnerActions from '../../actions/PartnerActions'
import routerUtils from '../../../../lib/routerUtils'

import Modals from '../../../../shared_components/core/modals/Modals'
import {ModalHeader} from '../../../../shared_components/core/modals/ModalHeaders'
import Closer from '../../../../shared_components/core/closer/Closer'
import currentUserWrapper from '../../../../shared_components/currentUserWrapper'
import Loader from '../../../../shared_components/NewLoader'
import PaymentPage from './PaymentPage'
import PremiumBanner from '../../../../shared_components/PremiumBanner'

import './paymentModal.scss'

Container.registerAction('partner', PartnerActions)

export class PaymentModal extends React.Component {
  constructor() {
    super()

    this.nextPage = this.nextPage.bind(this)
    this.modalCloser = this.modalCloser.bind(this)
    this.paymentMessaging = this.paymentMessaging.bind(this)
    this.premiumSectionClicked = this.premiumSectionClicked.bind(this)
    this.goToPreviousOrAccountSettingsRoute = this.goToPreviousOrAccountSettingsRoute.bind(this)
  }

  paymentMessaging() {
    if (this.props.partner.data.suppress_freemium) {
      return {
        subtitle: 'Enter your credit card below to start your subscription.',
        buttonText: 'Start subscription'
      }
    } else if (this.props.partner.data.suppress_trial) {
      return {
        subtitle: 'Enter your credit card below to upgrade to Premium.',
        buttonText: 'Start subscription'
      }
    } else {
      return {
        subtitle: 'Enter your credit card below to start your trial.',
        buttonText: 'Start your free trial'
      }
    }
  }

  premiumSectionClicked() { return this.props.location.query.premium_section_clicked === 'true' }

  // This forces a re-render, because account settings and nav won't have the new information without the re-render, maybe.
  nextPage(location) {
    if (this.props.itemName) // For now only want to log this event if the user got here by clicking a premium item
      MixpanelLogger.track('view_premium_purchase_successful', {context: this.props.context, item_name: this.props.itemName})

    routerUtils.setLocation(location)
  }

  goToPreviousOrAccountSettingsRoute() {
    const prevPath = this.props.location.query.prevPath

    if (prevPath)
      return routerUtils.push(prevPath)

    return routerUtils.setLocation(accountSettingsUrl)
  }

  modalCloser() {
    return (<Closer closer={this.goToPreviousOrAccountSettingsRoute} />)
  }

  render() {
    return (
      <Loader loading={this.props.loadingPartner}>
        <Modals.LinkPopUpModalLarge closerComponent={this.modalCloser} showModalOnMount={true}>
          {this.premiumSectionClicked() ? <PremiumBanner itemName={this.props.itemName} context={this.props.context} /> : null}
          <ModalHeader heading='Get the most out of Everplans' />
          <PaymentPage
            className='payment-modal'
            messaging={this.paymentMessaging()}
            nextPage={this.nextPage}
            partner={this.props.partner.data}
            currentUser={this.props.currentUser}
          />
        </Modals.LinkPopUpModalLarge>
      </Loader>
    )
  }
}


PaymentModal.propTypes = {
  currentUser: PropTypes.shape({}),
  itemName: PropTypes.string,
  context: PropTypes.string,
  loadingPartner: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      premium_section_clicked: PropTypes.string,
      prevPath: PropTypes.string
    })
  }),
  partner: PropTypes.shape({
    data: PropTypes.shape({
      coupon: PropTypes.object,
      suppress_freemium: PropTypes.bool,
      suppress_trial: PropTypes.bool
    })
  })
}

PaymentModal.defaultProps = {
  location: {
    query: {
      premium_section_clicked: 'false',
      prevPath: ''
    }
  },
  partner: {
    data: {
      coupon: {}
    }
  }
}

const mapStateToProps = ({items}, {location}) => {
  if (location.query.itemSlug && location.query.prevPath) {
    return {
      itemName: itemBySlug({itemSlug: location.query.itemSlug, items}).get('name'),
      context: location.query.prevPath.includes('everplan-preview') ? 'everplan_preview' : 'category_page'
    }
  }
}

export default connect(mapStateToProps)(currentUserWrapper(bindResources(PaymentModal, [{name: 'partner', type: 'itemNoId'}])))
